import React from "react"
import styles from "../styles/infocard.module.css"
import Img from "gatsby-image"

const Infocard = data => {
  return (
    <div className={`${styles.infocard} ${styles.shadow}`}>
      <div className={styles.image}>
        <Img fluid={data.data.image.fluid} />
      </div>

      <h2 className={styles.title}>{data.data.title}</h2>
      <div
        className={styles.paragraph}
        dangerouslySetInnerHTML={{
          __html: data.data.text.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

export default Infocard
