import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Infocard from "../components/infocard"
import style from "../styles/sites.module.css"
import SEO from "../components/seo"

function face({ data }) {
  return (
    <Layout>
      <SEO
        title="Face"
        description="In einem Vorgespräch und der individuellen Hautbildanalyse stimme ich die Pflege genau auf deine Bedürfnisse ab und erstelle dein persönliches Pflegekonzept."
      />
      <h1>Schöne & gepflegte Haut für jeden Typ</h1>
      <p>
        In einem Vorgespräch und der individuellen Hautbildanalyse stimme ich
        die Pflege genau auf deine Bedürfnisse ab. Gemeinsam sprechen wir über
        deine Bedürfnisse und Wünsche und erstellen dein persönliches
        Pflegekonzept.
      </p>

      <section className={style.services}>
        {data.face.nodes.map(item => {
          return <Infocard data={item} key={item.id} />
        })}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    face: allContentfulFace(sort: { order: ASC, fields: order }) {
      nodes {
        text {
          childMarkdownRemark {
            html
          }
        }
        id
        title
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default face
